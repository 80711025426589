import {
  BrandType,
  Certification,
  GeneratedCertifications,
  GeneratedLabels,
  GeneratedValues,
  GeneratedValuesAndCert,
  ResponseDataInactive,
  Scores,
  Value,
} from 'types/brand.types';

import Api from './Api';
import { AxiosResponse } from 'axios';

interface ResponseData {
  message: string;
  success: boolean;
}
type NoInfo = {
  questionnaireID: string;
  section: 'theme' | 'area';
  slug: string;
};

interface AutoSummaryResponse {
  message: string;
  success: boolean;
}
export default class ReviewsAPIs extends Api {
  constructor(...args: any[]) {
    super(...args);
    this.instance.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  // getAllReviews() {
  //   return this.instance.get('questionnaires');
  // }

  async getAllReviewsNew(): Promise<BrandType[]> {
    const response: AxiosResponse = await this.instance.get(
      'questionnaires/active',
    );
    return response?.data;
  }

  async getInactiveReviews(url: string): Promise<ResponseDataInactive> {
    const response: AxiosResponse = await this.instance.get(
      `/questionnaires/${url}`,
    );
    return response?.data;
  }

  async searchInactiveReviews(searchString: string): Promise<BrandType[]> {
    const response: AxiosResponse = await this.instance.post(
      'questionnaires/idle/search',
      { searchString },
    );
    return response?.data;
  }

  async getBrandByIdNew(brandUniqueId: string): Promise<BrandType> {
    const response: AxiosResponse = await this.instance.get(
      `questionnaire/${brandUniqueId}/short`,
    );
    return response.data as BrandType;
  }

  getReviewById(id: string) {
    return this.instance.get(`/questionnaire/${id}`);
  }

  async startNewReview(ratingId: string): Promise<BrandType> {
    const response = await this.instance.post(
      `/questionnaire/${ratingId}/start`,
    );
    return response?.data?.id;
  }

  async startPeerReview(ratingId: string | number): Promise<ResponseData> {
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${ratingId}/start-peer-review`,
    );
    return response?.data?.message;
  }

  // summary
  async saveSummary(
    questionnaireID: string | number,
    summary: string,
  ): Promise<ResponseData> {
    const payload = {
      summary,
    };
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${questionnaireID}/autoSummary`,
      payload,
    );
    return response.data;
  }

  async deleteSummary(questionnaireID: string | number): Promise<ResponseData> {
    const response: AxiosResponse = await this.instance.delete(
      `questionnaire/${questionnaireID}/autoSummary`,
    );
    return response.data;
  }

  getQuestionsFromSingleItem(questionnaireID: string, itemSlug: string) {
    return this.instance.get(
      `questionnaire/${questionnaireID}/item/${itemSlug}/questions`,
    );
  }

  postCompleteReview(questionnaireID: string, needsPeerReview: boolean) {
    return this.instance.post(`questionnaire/${questionnaireID}/complete`, {
      needsPeerReview,
    });
  }

  // finalise
  async finaliseReview(
    questionnaireID: string | number,
    summary: string,
    values: GeneratedValues,
    certifications: GeneratedCertifications,
    labels: GeneratedLabels | [],
  ): Promise<ResponseData> {
    console.log(labels);
    const payload = {
      summary,
      values,
      certifications,
      labels,
    };

    console.log('payload', payload);
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${questionnaireID}/finalise`,
      JSON.stringify(payload),
    );
    return response.data;
  }

  // answers
  async putAcceptAnswers(ratingId: string, questionId: string) {
    const response: AxiosResponse = await this.instance.put(
      `questionnaire/${ratingId}/question/${questionId}/accept`,
    );
    return response.data;
  }

  async undoAcceptAnswer(ratingId: string, questionId: string) {
    const response: AxiosResponse = await this.instance.put(
      `questionnaire/${ratingId}/question/${questionId}/reset`,
    );
    return response.data;
  }

  async deleteAnswersEvidences(
    ratingId: string,
    itemSlug: string,
    questionId: string,
  ) {
    const response: AxiosResponse = await this.instance.delete(
      `questionnaire/${ratingId}/item/${itemSlug}/question/${questionId}/answer-evidence`,
    );
    return response.data;
  }

  putAnswersQuestion(
    questionnaireID: string,
    itemSlug: string,
    questionId: string,
    payload: any,
  ) {
    return this.instance.put(
      `questionnaire/${questionnaireID}/item/${itemSlug}/question/${questionId}/edit`,
      payload,
    );
  }

  putCreateAnswersQuestion(
    questionnaireID: string,
    itemSlug: string,
    questionId: string,
    payload: any,
  ) {
    return this.instance.post(
      `questionnaire/${questionnaireID}/item/${itemSlug}/question/${questionId}/create`,
      payload,
    );
  }

  // evidences
  putEvidencesQuestion(
    questionnaireID: string,
    questionId: string,
    payload: any,
  ) {
    return this.instance.post(
      `questionnaire/${questionnaireID}/question/${questionId}/evidences`,
      payload,
    );
  }
  // ---------- comments

  upsertComent(questionnaireID: string, questionId: string, data: any) {
    return this.instance.post(
      `questionnaire/${questionnaireID}/question/${questionId}/comment/upsert`,
      data,
    );
  }

  deleteComment(questionnaireID: string, questionId: string) {
    return this.instance.delete(
      `questionnaire/${questionnaireID}/question/${questionId}/comment/delete`,
    );
  }

  async addCommentBrandPage(
    questionnaireID: string,
    notes: string,
  ): Promise<ResponseData> {
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${questionnaireID}/brand/notes/edit`,
      { notes },
    );
    return response?.data;
  }

  async deleteCommentBrandPage(questionnaireID: string): Promise<ResponseData> {
    const response: AxiosResponse = await this.instance.delete(
      `questionnaire/${questionnaireID}/brand/notes`,
    );
    return response?.data;
  }

  // end comments

  // no-info
  async activateNoInfo({
    questionnaireID,
    section,
    slug,
  }: NoInfo): Promise<BrandType[]> {
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${questionnaireID}/${section}/${slug}/no-info`,
    );
    return response?.data;
  }

  async deactivateNoInfo({
    questionnaireID,
    section,
    slug,
  }: NoInfo): Promise<BrandType[]> {
    const response: AxiosResponse = await this.instance.delete(
      `questionnaire/${questionnaireID}/${section}/${slug}/no-info`,
    );
    return response?.data;
  }
  // end no-info

  putResumeFrom(ratingId: string, data: any) {
    return this.instance.put(`rating/${ratingId}/resume/from`, data);
  }

  patchQuestionPeerReview(id: string, qId: string, data: any) {
    return this.instance.patch(`rating/${id}/question/${qId}/peerReview`, data);
  }

  async getQuestionnaireScore(questionnaireID: string): Promise<Scores> {
    const response: AxiosResponse = await this.instance.get(
      `questionnaire/${questionnaireID}/score`,
    );
    return response?.data;
  }

  async getAutosummary(questionnaireID: string): Promise<AutoSummaryResponse> {
    const response: AxiosResponse = await this.instance.get(
      `questionnaire/${questionnaireID}/autoSummary`,
    );
    return response?.data;
  }

  async generateScoringLabels(
    questionnaireID: string,
  ): Promise<GeneratedLabels> {
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${questionnaireID}/labels`,
    );
    return response?.data;
  }

  async generateScoringValuesAndCert(
    questionnaireID: string,
  ): Promise<GeneratedValuesAndCert> {
    const response: AxiosResponse = await this.instance.post(
      `questionnaire/${questionnaireID}/values-and-certifications`,
    );
    return response?.data;
  }

  async startQuestionnaireScoreCalculation(
    questionnaireID: string,
  ): Promise<ResponseData> {
    const response: AxiosResponse = await this.instance.post(
      `scoring/questionnaire/${questionnaireID}/start`,
    );
    return response?.data;
  }
}
